import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { Hero, Purpose, Switcher, WhyAdudev } from "../containers"

import OurTeam from "../components/ourTeam"
import LogoSectionAbout from "../components/Sections/logoSectionAbout"
import OurPartners from "../components/Sections/ourPartners"
import { BreadCrumb, MoreReasons, TitleV2 } from "../components"
import reasons from "../constants/reasons"
import AboutCrumbs from "../constants/BreadCrumbs/AboutCrumbs"

//import OurExecutivesNew from "../components/Sections/ourExecutivesNew"

const AboutPage = ({ data }) => {
  //console.log("Data:", data)
  const image = getImage(
    data.strapiAboutPage.image.localFile.childImageSharp.gatsbyImageData
  )

  const visionDetails = data.strapiVision
  const missionDetails = data.strapiMission

  return (
    <>
      <Hero image={image} title={data.strapiAboutPage.title} />
      <BreadCrumb crumbs={AboutCrumbs} />
      <div className="uk-margin-small-top">
        <TitleV2 title="Who we are" />
      </div>

      <Switcher visionDetails={visionDetails} missionDetails={missionDetails} />

      <Purpose />
      <OurTeam />
      <div className="uk-section uk-section-muted">
        <div className="uk-container">
          <TitleV2 title="Featured Partners" />
          <div className="uk-margin-medium-top">
            <OurPartners />
          </div>
        </div>
      </div>
      <WhyAdudev />
      <MoreReasons reasons={reasons} />
      <div className="uk-section uk-section-default">
        <div className="uk-container">
          <TitleV2 title="Where we Operate" />
          <div className="uk-margin-medium-top">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d19859.474434226304!2d-0.6734934499999999!3d51.5235936!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2suk!4v1635775315621!5m2!1sen!2suk"
              width="100%"
              height="415"
              frameBorder="0"
              loading="lazy"
              title="map"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
      <LogoSectionAbout />
    </>
  )
}

export default AboutPage

export const query = graphql`
  {
    strapiAboutPage {
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(formats: AUTO, placeholder: BLURRED)
          }
        }
      }
      title
    }
    strapiVision {
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(height: 400, placeholder: BLURRED, formats: AUTO)
          }
        }
      }
      title
      adudev
      content
      startUp {
        title
        description
        id
        slug
      }
    }
    strapiMission {
      title
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(formats: AUTO, placeholder: BLURRED, height: 400)
          }
        }
      }
      adudev
      content
      partners {
        id
        title
        description
        slug
      }
    }
  }
`

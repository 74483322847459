import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { TitleV2 } from "."

const OurTeam = () => {
  return (
    <div className="uk-section uk-position-relative uk-overflow-hidden">
      <div className="uk-container uk-position-relative">
        {/*  <h3>Our Leadership Team</h3> */}
        <TitleV2 title="Our Leadership team" />
        <div
          className="uk-flex uk-flex-center uk-margin-large-top"
          uk-grid="true"
        >
          <div className="uk-width-1-2 uk-width-1-2@m uk-inline-clip uk-transition-toggle uk-animation-fade uk-animation-slide-left-medium team-member-item">
            <div className="uk-background-cover uk-inline-clip uk-transition-toggle uk-margin-bottom uk-position-relative cover-image">
              <StaticImage
                src={"../images/team-member-1.jpeg"}
                alt="Modal Image"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="blurred"
                layout="constrained"
              />
              <div className="uk-transition-fade uk-position-cover uk-overlay-primary uk-overlay-dark uk-flex uk-flex-center uk-flex-middle">
                <ul className="uk-iconnav">
                  <div
                    className="uk-flex-middle uk-grid-small uk-child-width-auto"
                    uk-grid="true"
                  >
                    <div>
                      <a
                        uk-tooltip="title: Twitter; pos: bottom-center"
                        href="https://twitter.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="twitter"
                        alt=""
                        className="uk-icon-button"
                        uk-icon="twitter"
                      >
                        {null}
                      </a>
                    </div>

                    <div>
                      <a
                        uk-tooltip="title: Linkedin; pos: bottom-center"
                        href="https://www.linkedin.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="linkedin"
                        alt=""
                        className="uk-icon-button"
                        uk-icon="linkedin"
                      >
                        {null}
                      </a>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
            <span className="uk-display-block uk-text-bold">Herman Adu</span>
            <span className="uk-display-block">CEO - FOUNDER</span>
          </div>

          <div className="uk-width-1-2 uk-width-1-2@m uk-inline-clip uk-transition-toggle uk-animation-fade uk-animation-slide-top-medium team-member-item">
            <div className="uk-background-cover uk-inline-clip uk-transition-toggle uk-margin-bottom uk-position-relative cover-image">
              <StaticImage
                src={"../images/team-member-2.jpeg"}
                alt="Modal Image"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="blurred"
                layout="constrained"
              />
              <div className="uk-transition-fade uk-position-cover uk-overlay-primary uk-overlay-dark uk-flex uk-flex-center uk-flex-middle">
                <ul className="uk-iconnav">
                  <div
                    className="uk-flex-middle uk-grid-small uk-child-width-auto"
                    uk-grid="true"
                  >
                    <div>
                      <a
                        uk-tooltip="title: Twitter; pos: bottom-center"
                        href="https://twitter.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="twitter"
                        alt=""
                        className="uk-icon-button"
                        uk-icon="twitter"
                      >
                        {null}
                      </a>
                    </div>

                    <div>
                      <a
                        uk-tooltip="title: Instagram; pos: bottom-center"
                        href="https://www.instagram.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="instagram"
                        alt=""
                        className="uk-icon-button"
                        uk-icon="instagram"
                      >
                        {null}
                      </a>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
            <span className="uk-display-block uk-text-bold">Sarah Adu</span>
            <span className="uk-display-block">CEO - FOUNDER</span>
          </div>

          {/* <div className="uk-width-1-2 uk-width-1-4@m uk-inline-clip uk-transition-toggle uk-animation-fade uk-animation-slide-bottom-medium team-member-item">
            <div className="uk-background-cover uk-inline-clip uk-transition-toggle uk-margin-bottom uk-position-relative cover-image">
              <StaticImage
                src={"../images/team-member-3.jpeg"}
                alt="Modal Image"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="blurred"
                layout="constrained"
              />
              <div className="uk-transition-fade uk-position-cover uk-overlay-primary uk-overlay-dark uk-flex uk-flex-center uk-flex-middle">
                <ul className="uk-iconnav">
                  <div
                    className="uk-flex-middle uk-grid-small uk-child-width-auto"
                    uk-grid="true"
                  >
                    <div>
                      <a
                        uk-tooltip="title: Instagram; pos: bottom-center"
                        href="https://www.instagram.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="instagram"
                        alt=""
                        className="uk-icon-button"
                        uk-icon="instagram"
                      >
                        {null}
                      </a>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
            <span className="uk-display-block uk-text-bold">Holy Patel</span>
            <span className="uk-display-block">SKYPENCIL - CEO - FOUNDER</span>
          </div> */}

          {/* <div className="uk-width-1-2 uk-width-1-4@m uk-inline-clip uk-transition-toggle uk-animation-fade uk-animation-slide-right-medium team-member-item">
            <div className="uk-background-cover uk-inline-clip uk-transition-toggle uk-margin-bottom uk-position-relative cover-image">
              <StaticImage
                src={"../images/team-member-4.jpg"}
                alt="Modal Image"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="blurred"
                layout="constrained"
              />
              <div className="uk-transition-fade uk-position-cover uk-overlay-primary uk-overlay-dark uk-flex uk-flex-center uk-flex-middle">
                <ul className="uk-iconnav">
                  <div
                    className="uk-flex-middle uk-grid-small uk-child-width-auto"
                    uk-grid="true"
                  >
                    <div>
                      <a
                        uk-tooltip="title: Twitter; pos: bottom-center"
                        href="https://twitter.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="twitter"
                        alt=""
                        className="uk-icon-button"
                        uk-icon="twitter"
                      >
                        {null}
                      </a>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
            <span className="uk-display-block uk-text-bold">Hiten Patel</span>
            <span className="uk-display-block">SKYPENCIL - CEO - FOUNDER</span>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default OurTeam

import React from "react"

const OurPartners = () => {
  return (
    <div className="uk-container">
      {/* <h3>Our Parners</h3> */}
      <hr className="uk-divider-icon" />

      <ul uk-accordion="true">
        <li>
          <a className="uk-accordion-title" href="/">
            SkyPencil
          </a>
          <div className="uk-accordion-content">
            <p>
              Logo and bio of SkyPencil - save screen space having each partner
              detail in accordion
            </p>
          </div>
        </li>
        <li>
          <a className="uk-accordion-title" href="/">
            Shopify
          </a>
          <div className="uk-accordion-content">
            <p>
              Logo and bio of Shopify - save screen space having each partner
              detail in accordion
            </p>
          </div>
        </li>
        <li>
          <a className="uk-accordion-title" href="/">
            Strapi
          </a>
          <div className="uk-accordion-content">
            <p>
              Logo and bio of Strapi - save screen space having each partner
              detail in accordion
            </p>
          </div>
        </li>
      </ul>

      <hr className="uk-divider-icon" />
    </div>
  )
}

export default OurPartners

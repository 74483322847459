const AboutCrumbs = [
  {
    id: 1,
    title: "Home",
    slug: "/",
  },
  {
    id: 2,
    title: "About",
    slug: "/about",
  },
]

export default AboutCrumbs
